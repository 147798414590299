export const localesRU = {
  adapterLabel: 'Сменить пароль',
  dialogTitle: 'Смена пароля',
  submitLabel: 'Сменить',
  cancelLabel: 'Отмена',
  inputNames: {
    oldPass: 'Старый пароль',
    newPass: 'Новый пароль',
    confirmPass: 'Подтверждение пароля',
  },
  emptyMessages: 'Поле "{name}" обязательно',
  validateMessages: {
    forbiddenSymbols: 'Символы > < " \' = ; ( ) не допустимы',
    lowerLetters: 'Пароль должен включать строчные буквы',
    upperLetters: 'Пароль должен включать заглавные буквы',
    digitsInclude: 'Пароль должен включать цифры',
    specialSymbols: 'Пароль должен включать специальные символы',
    nonCyrillic: 'Кириллица не допустима',
    passLength: 'Длина пароля - от {minLength} до {maxLength} символов.',
    mismatchPass: 'Пароли не совпадают!',
  },
  responseInfo: {
    success: 'Смена пароля прошла успешно',
    rightsError: 'У вас нет прав. Пожалуйста, обратитесь к Администратору.',
    oldPassError: 'Старый пароль введен неверно.',
    changeError: 'Ошибка при отправке запроса',
  },
}
