import { Ref, reactive, ref, computed, ComputedRef } from 'vue'
import validRules from '@/utils/validateRules'
import { useI18n } from 'vue-i18n'

type Field = {
  credential: string
  isValid: boolean
  errors: string[]
}

interface InputFields {
  [key: string]: {
    credential: string
    isValid: boolean
    errors: string[]
  }
}

type ValidateData = {
  name: InputFieldName
  credential: string
  minLength: number
  maxLength: number
  passRules?: string[]
}

type UseValidateType = {
  inputFields: InputFields
  formMessages: ComputedRef<string[]>
  currentIndex: Ref<number>
  isButtonInvalid: ComputedRef<boolean>
  fieldsList: string[]
  incrementIndex: () => void
  decrementIndex: () => void
  validate: (data: ValidateData) => void
  reset: () => void
}

export function useValidate(
  oldPassRequired?: boolean | undefined,
): UseValidateType {
  const inputFields = reactive({
    oldPass: { credential: '', isValid: true, errors: [] as string[] },
    newPass: { credential: '', isValid: true, errors: [] as string[] },
    confirmPass: { credential: '', isValid: true, errors: [] as string[] },
  })
  const currentIndex = ref<number>(0)
  const formMessages = computed(() => {
    const messages = new Set()
    for (const fieldName in inputFields) {
      const field = inputFields[fieldName as keyof typeof inputFields]
      for (const error of field.errors) {
        messages.add(error)
      }
    }
    return Array.from(messages)
  }) as ComputedRef<string[]>
  const fieldsList = oldPassRequired
  ? ['oldPass', 'newPass', 'confirmPass']
  : ['newPass', 'confirmPass']

  const { t } = useI18n()

  const { newPass, oldPass, confirmPass } = inputFields

  const invalidCondition = () => {
    return (
      newPass.credential === '' ||
      confirmPass.credential === '' ||
      formMessages.value.length > 0
    )
  }
  const isButtonInvalid = computed(() => {
    return oldPassRequired
      ? invalidCondition() || oldPass.credential === ''
      : invalidCondition()
  })

  const incrementIndex = () => {
    const newIndex = currentIndex.value + 1
    if (newIndex < formMessages.value.length) {
      currentIndex.value = newIndex
    } else {
      currentIndex.value = formMessages.value.length
    }
  }

  const decrementIndex = () => {
    if (currentIndex.value > 0) {
      currentIndex.value -= 1
    } else {
      currentIndex.value = 0
    }
  }

  const removeErrors = (field: { errors: string[] }, errorMessage: string) => {
    field.errors = field.errors.filter((error) => error !== errorMessage)
  }

  const addErrors = (field: { errors: string[] }, errorMes: string) => {
    if (!field.errors.includes(errorMes)) {
      field.errors.push(errorMes)
    }
  }

  const setFieldValidity = () => {
    for (const field in inputFields) {
      const item = inputFields[field as keyof typeof inputFields]
      if (Array.isArray(item.errors) && item.errors.length > 0) {
        item.isValid = false
      } else {
        item.isValid = true
      }
    }
  }

  const validate = (data: ValidateData): void => {
    const { name, credential, minLength, maxLength, passRules } = data
    const field: Field = inputFields[name]
    const INPUT_NAME = `inputNames.${name}`
    const LENGTH_MES = 'validateMessages.passLength'
    const MISMATCH_MES = 'validateMessages.mismatchPass'

    if (credential === '') {
      addErrors(field, t('emptyMessages', { name: t(INPUT_NAME) }))
    } else {
      removeErrors(field, t('emptyMessages', { name: t(INPUT_NAME) }))
      decrementIndex()
    }

    if (passRules && name === 'newPass' && credential) {
      const rulesToApply = validRules.filter((rule) =>
        passRules.includes(rule.name),
      )
      rulesToApply.forEach(({ reg, name }) => {
        if (!reg.test(field.credential)) {
          addErrors(field, t(`validateMessages.${name}`))
        } else {
          removeErrors(newPass, t(`validateMessages.${name}`))
          decrementIndex()
        }
      })
    }

    if (
      credential &&
      name === 'newPass' &&
      (credential.length < minLength || credential.length > maxLength)
    ) {
      addErrors(
        field,
        t(LENGTH_MES, { minLength, maxLength }),
      )
    } else {
      removeErrors(
        field,
        t(LENGTH_MES, { minLength, maxLength }),
      )
      decrementIndex()
    }
    if (
      newPass.credential &&
      confirmPass.credential &&
      newPass.credential !== confirmPass.credential
    ) {
      addErrors(newPass, t(MISMATCH_MES))
      addErrors(confirmPass, t(MISMATCH_MES))
    } else {
      removeErrors(confirmPass, t(MISMATCH_MES))
      removeErrors(newPass, t(MISMATCH_MES))
      decrementIndex()
    }
    setFieldValidity()
  }

  const reset = () => {
    currentIndex.value = 0
    for (const field of Object.keys(inputFields)) {
      inputFields[field as keyof typeof inputFields].credential = ''
      inputFields[field as keyof typeof inputFields].isValid = true
      inputFields[field as keyof typeof inputFields].errors = []
    }
  }

  return {
    inputFields,
    formMessages,
    currentIndex,
    isButtonInvalid,
    validate,
    reset,
    incrementIndex,
    decrementIndex,
    fieldsList,
  }
}
