export const localesEN = {
  adapterLabel: 'Change Password',
  dialogTitle: 'Password Change',
  submitLabel: 'Change',
  cancelLabel: 'Cancel',
  inputNames: {
    oldPass: 'Old Password',
    newPass: 'New Password',
    confirmPass: 'Confirm Password',
  },
  emptyMessages: 'Field "{name}" is required',
  validateMessages: {
    forbiddenSymbols: 'Symbols > < " \' = ; ( ) are not allowed',
    lowerLetters: 'Password must include lowercase letters',
    upperLetters: 'Password must include uppercase letters',
    digitsInclude: 'Password must include numbers',
    specialSymbols: 'Password must include special symbols',
    nonCyrillic: 'Cyrillic characters are not allowed',
    passLength: 'Password length from {minLength} to {maxLength} characters',
    mismatchPass: 'Password mismatch',
  },
  responseInfo: {
    success: 'Password changed successfully',
    rightsError: 'You don\'t have rights. Please contact the Administrator.',
    oldPassError: 'Old password entered incorrectly.',
    changeError: 'Error changing password',
  },
}
