const validRules: {
  name: string
  reg: RegExp
}[] = [
    {
      name: 'forbiddenSymbols',
      // eslint-disable-next-line no-useless-escape
      reg: /^[^/<>\"'=();]*$/
    },
    {
      name: 'lowerLetters',
      reg: /[a-z]/
    },
    {
      name: 'upperLetters',
      reg: /[A-Z]/
    },
    { name: 'digitsInclude', reg: /\d/ },
    {
      name: 'specialSymbols',
      reg: /[!@#$%^&*()_+\-=[\]{}|:.,./?]/
    },
    {
      name: 'nonCyrillic',
      reg: /^[^А-Яа-яЁё]+$/
    }
]
export default validRules
