import './set-public-path'
import PrimeVue from 'primevue/config'
import { h, createApp } from 'vue'
import App from './App.vue'
import { createI18n } from 'vue-i18n'
import { locales } from './locales/index'
import singleSpaVue from 'single-spa-vue'
import ToastService from 'primevue/toastservice'

const locale = 'ru'

const i18n = createI18n({
  locale,
  messages: locales
})

const vueLifecycles = singleSpaVue({
  createApp,
  appOptions: {
    render() {
      return h(App, {
        props: this.props
      })
    },
  },
  handleInstance: (app: ReturnType<typeof createApp>) => {
    app.use(i18n)
    app.use(PrimeVue)
    app.use(ToastService)
  },
})

export const bootstrap = vueLifecycles.bootstrap
export const mount = (props: {
  domElement: Element
  vueAppend: unknown
}): Promise<unknown> => {
  if (props.domElement && props.vueAppend) {
    const el = document.createElement('div')
    props.domElement.appendChild(el)
    props.domElement = el
  }
  return vueLifecycles.mount(props)
}
export const unmount = vueLifecycles.unmount
